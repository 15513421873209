<template>
  <div>
    <v-card>
      <v-toolbar dense dark color="primary">
        <v-toolbar-title><h4 class="font-weight-light">CHEQUE BOOK PADS FOR VOUCHER SUPPLY</h4>
        </v-toolbar-title>
      </v-toolbar>
      <v-layout row wrap class="align-center mx-2 mt-5">
        <v-flex xs12 md12>
          <v-form ref="form" class="multi-col-validation">
            <v-layout row wrap class="mx-1 mb-5">
              <v-flex xs12 md6>
                <v-select
                  v-model="bank_id"
                  class="mx-2"
                  dense
                  outlined
                  label="Bank"
                  :items="bank_items"
                  item-value="id"
                  item-text="bank_code"
                  @change="selected_bank"
                  :rules="rules.combobox_rule"
                ></v-select>
              </v-flex>
              <v-flex xs12 md6>
                <v-select
                  v-model="cheque_no"
                  class="mx-2"
                  dense
                  outlined
                  label="Cheque"
                  :items="cheque_items"
                  item-value="id"
                  item-text="cheque_no"
                  :rules="rules.combobox_rule"
                  @change="selected_cheque"
                ></v-select>
              </v-flex>

              <v-col cols="12" v-show="alert">
                <v-alert
                  color="warning"
                  text
                  class="mb-0"

                >
                  <div class="d-flex align-start">
                    <v-icon color="warning">
                      {{ icons.mdiAlertOutline }}
                    </v-icon>

                    <div class="ms-3">
                      <p class="text-base font-weight-medium mb-1">
                        {{alert_message}}
                      </p>
                    </div>
                  </div>
                </v-alert>
              </v-col>
              <v-col cols="12" md="12" v-if="this.data_items.length>0">
                <v-btn
                  class="w-full"
                  color="primary"
                  @click="save_voucher"
                  v-if="!saving_data"
                >
                  Save Changes
                </v-btn>
                <v-progress-circular
                  :size=50
                  :width="5"
                  color="primary"
                  indeterminate
                  v-else
                ></v-progress-circular>
              </v-col>
            </v-layout>
          </v-form>
        </v-flex>
        <v-flex xs12 md12 class="mx-2" v-if="data_items.length>0">
          <v-data-table dense
                        :headers="headers"
                        :items="data_items"
          >
            <template v-slot:item="{ item }">
              <tr>
                <td>
                  {{ item }}
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-flex>
      </v-layout>
    </v-card>
    <snack-bar-dialog
      :snackbar_flag="this.snackbar"
      :color="this.snackbar_color"
      :snackbar_text="this.snackbar_text"
    />
  </div>
</template>

<script>
  import {mdiAccountOutline, mdiAccountCashOutline, mdiInformationOutline} from "@mdi/js";
  import moment from "moment";
  import {mapActions, mapGetters} from "vuex";
  import snackBarDialog from '@/components/dialogs/notifications_dialog/Snackbar'
  import category from "@/store/module/data/category";

  const initialState = () => {
    return {
      saving_data: false,
      alert: false,
      alert_message: '',
      headers: [
        {text: 'SERIES', value: 'date_of_deposit', sortable: false},
      ],
      data_items: [],
      cheque_no: '',
      bank_id: '',
      bank_items: [],
      cheque_items: [],
      from_cheque_no: 0,
      to_cheque_no: 0,
    }
  }
  export default {
    components: {
      snackBarDialog,
    },
    setup() {
      return {
        icons: {
          mdiAccountOutline,
          mdiAccountCashOutline,
          mdiInformationOutline,
        },
      }
    },
    data() {
      return initialState()
    },
    mounted() {
      this.initialize_data()
    },
    computed: {
      ...mapGetters('form_rules', ['rules']),
      ...mapGetters('authentication', ['employee_id', 'month_of']),
      ...mapGetters('system_data', ['snackbar', 'snackbar_color', 'snackbar_text', 'company_logo']),
    },
    methods: {
      ...mapActions('system_data', ['change_snackbar']),
      ...mapActions('cheque_book_series_pads', ['list_of_available_pads_for_voucher', 'save_cheque_book_pads_to_supply']),
      initialize_data() {
        this.list_of_available_pads_for_voucher()
          .then(response => {
            this.cheque_items = []
            this.data_items = []
            this.from_cheque_no = 0
            this.to_cheque_no = 0
            this.bank_items = response.data
          })
          .catch(error => {
            console.log(error)
          })
      },
      date_format(value) {
        return moment(value)
      },
      formatPrice(value) {
        let val = (value / 1).toFixed(2).replace(',', '.')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      },
      save_voucher() {
        this.saving_data = true
        this.alert = false
        if (this.$refs.form.validate()) {
          const data = new FormData()
          data.append('bank_id', this.bank_id);
          data.append('from_cheque_no', this.from_cheque_no);
          data.append('to_cheque_no', this.to_cheque_no);
          this.save_cheque_book_pads_to_supply(data)
            .then(response => {
              var color = 'error'
              if (response.status === 200) {
                var color = 'success'
              }
              this.change_snackbar({
                show: true,
                color: color,
                text: response.data,
              })
              this.saving_data = false
              Object.assign(this.$data, initialState())
              this.initialize_data()
            })
            .catch(error => {
              console.log(error)
            })
        } else {
          this.alert = true
          this.alert_message = 'Please Fill up the field/s'
          this.saving_data = false
        }
      },
      selected_bank(value) {
        var index = this.bank_items.map(function (x) {
          return x.id;
        }).indexOf(value)
        if (index != -1) {
          this.data_items = []
          this.from_cheque_no = 0
          this.to_cheque_no = 0
          this.cheque_items = this.bank_items[index].cheque_book_series_pads
        }
      },
      selected_cheque(value) {
        var index = this.cheque_items.map(function (x) {
          return x.id;
        }).indexOf(value)
        if (index != -1) {
          this.data_items = []
          this.from_cheque_no = this.cheque_items[index].from_cheque_no
          this.to_cheque_no = this.cheque_items[index].to_cheque_no
          for (var x = this.cheque_items[index].from_cheque_no; x <= this.cheque_items[index].to_cheque_no; x++) {
            this.data_items.push(x)
          }
        }
      }
    },
  }
</script>
